<template>
    <div>
        <bread-crumbs :items="items" />
        <v-tabs
        grow
        v-model="active_tab"
        >
            <v-tab
            v-for="tab of tabs"
            :key="tab.index"
            :to="tab.href"
            >
            {{tab.name}}
            </v-tab>
        </v-tabs>
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue'
export default{
    components: {BreadCrumbs},
    data() {
        return {
            active_tab:0,
            items: [
                {
                    text: 'المحاسبة',
                    disabled: true,
                    to: '/finance',
                },
            ],
            
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function() {
            return [
                {
                    index:0,name:this.lang.purchase,href:'/inventory/purchase',class:'mytab5',
                },
                {
                    index:1,name:this.lang.product_categories,href:'/inventory/products-cats', class: 'mytab3'
                },
                {
                    index:2,name:this.lang.inventory,href:'/inventory/products', class: 'mytab1'
                },
                {
                    index:3,name:' الاكثر مبيعا',href:'/inventory/best-seller', class: 'mytab3'
                },
                {
                    index:4,name:' الاقل مبيعا',href:'/inventory/lowest-selling', class: 'mytab5'
                },
                {
                    index:5,name:' المنتهية',href:'/inventory/finished', class: 'mytab'
                },
                {
                    index:5,name:'حركة صنف',href:'/inventory/itemtrans', class: 'mytab4'
                }
            ]
        }
    },
}
</script>